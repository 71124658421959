<template>
  <div class="login-container">
    <div class="login" :style="{'background-image':`url(${require(`../../assets/login/${$store.getters.moduleName}/bg.png`)})`}">
      <div class="topnav">
        <img class="img" :src="require(`@/assets/login/${$store.getters.moduleName}/logo.png`)">
        <div class="text" @click="$go('/')">
          <img :src="require(`@/assets/login/${$store.getters.moduleName}/2.png`)" style="width:22px;height:22px">
          <div style="color:var(--theme)">返回首页</div>
          <img :src="require(`@/assets/login/${$store.getters.moduleName}/1.png`)" style="width:25px;height:16px">
        </div>
      </div>

      <div class="bottomDiv">

        <!-- 正常登录 -->
        <login v-show="type===0" />
        <!-- 微信扫码登录 -->
        <div v-show="type===1" class="login-form">
          <div class="wxsm-out">
            <div class="wxsm"><div class="bg" />账号密码登录</div>
            <img class="click" :src="require(`@/assets/login/${$store.getters.moduleName}/10.png`)" @click="type=0">
          </div>
          <div class="loginText">
            <img style="width:42px;height:33px;margin-right:9px" :src="require(`@/assets/login/${$store.getters.moduleName}/9.png`)" alt="">
            微信扫码登录</div>
          <div v-loading="loading" class="erWeiMa">
            <div v-show="show" id="qrcode" class="center" />
          </div>
        </div>

        <!-- 注册 -->
        <div v-show="type===2" class="login-form" style="padding:50px 50px 10px 50px;">
          <div class="loginText">
            新用户注册</div>
          <register />
        </div>

        <!-- 忘记密码 -->
        <div v-show="type===3" class="login-form" style="padding:50px 50px 10px 50px;min-height:390px">
          <div class="loginText" style="margin-bottom:40px">
            找回密码</div>
          <forget />
        </div>
      </div>
    </div>
    <footer>
      <div><a href="http://beian.miit.gov.cn/" target="_blank">粤ICP备17074671号-17</a></div>
      <div>智慧党建版 权 所 有 ，未 经 书 面 授 权 禁 止 使 用</div>
      <div>Copyright © 1997-2021 by zhihuidanjian. all rights reserved</div>
    </footer>
  </div>
</template>

<script>
import register from './components/register.vue'
import login from './components/login.vue'
import forget from './components/forget.vue'
import QRCode from 'qrcodejs2'
let timeOut; let isStop = false; let msg
export default {
  name: 'Login',
  components: { register, login, forget },
  data() {
    return {
      type: 0, // 0普通登录 1微信扫码登录 2注册 3忘记密码
      loading: false,
      show: false,
      times: 0
    }
  },
  watch: {
    type(val) {
      if (val == 1 && !this.show) {
        this.init()
      }
    }
  },
  destroyed() {
    this.clearTimeOut()
    isStop = true
  },
  created() {
    timeOut = ''
    isStop = false
    msg = ''
  },
  methods: {
    init() {
      this.$store.dispatch('login/AccountGetAuthUrl').then(res => {
        this.showQrcode(res.data.path)
        this.authId = res.data.authId
        this.checkLogin()
      })
      document.addEventListener('visibilitychange', () => {
        if (!document.hidden) { // 处于当前页面
          if (!timeOut) { this.checkLogin() }
        } else {
          this.clearTimeOut()
        }
      })
    },
    showQrcode(url) {
      this.$nextTick(() => {
        new QRCode('qrcode', {
          text: url, // URL地址
          colorDark: '#000', // 二维码颜色
          colorLight: '#ffffff' // 背景颜色
        })
        this.loading = false
        setTimeout(() => {
          const a = document.querySelector('#qrcode img')
          a.style.width = '200px'
          this.show = true
        }, 50)
      })
    },
    checkLogin() {
      if (isStop) return

      this.$store.dispatch('login/AccountWxLogin', { authId: this.authId }).then(res => {
        if (!res.data || !res.data.wxOpenId) {
          if (this.times >= 500) {
            this.$message({
              message: '页面已失效,请刷新页面',
              type: 'error',
              duration: 99999999
            })
          } else {
            this.checkMsgIsShow(res.msg)
            this.times += 1
            timeOut = setTimeout(() => {
              this.checkLogin()
            }, 1000)
          }
        } else {
          this.$store.commit('app/LOGIN', res.data)
          this.$message.success('登录成功')
          this.clearTimeOut()
        }
      })
    },
    clearTimeOut() {
      if (timeOut) {
        clearTimeout(timeOut)
        timeOut = null
      }
    },
    checkMsgIsShow(resMsg) { // 判断该错误信息是否提示过
      if (resMsg == '扫码登录失败') return
      if (msg != resMsg) {
        msg = resMsg
        this.$message({
          message: msg || 'Error',
          type: 'error',
          duration: 2 * 1000
        })
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.login-container{
  width: 100vw;
  height: 100vh;
}
$dark_gray:#889aa4;
$light_gray:#eee;
.login{
  background-size: cover;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.img{
  width:210px;
  height:70px;
}
.text{
  font-size:10px;
  color:#ffc693;
}
.topnav{
  display:flex;
  align-items:center;
  justify-content:space-around;
  background:rgba(255,255,255,.6);
  height:90px;
  .text{
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 136px;
    justify-content: space-between;
    div{
      font-size: 18px;
    }
  }
}
.bottomDiv{
  flex:1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-form {
    position: relative;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(239, 140, 0, 0.26);
    border-radius: 20px;
    width: 600px;
    min-height: 452px;
    .backPassword{
      cursor: pointer;
      width: 72px;
      height: 16px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-left: auto;
      margin-bottom: 30px;
    }
    .register{
      height: 15px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: var(--theme);
      text-align: center;
      margin-top: 20px;
    }
    .wxsm-out{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .wxsm{
        width: 146px;
        height: 36px;
        border: 1px solid var(--theme);
        border-radius: 4px;
        color: var(--theme);
        text-align: center;
        line-height: 36px;
        position: relative;
        .bg{
          background: var(--theme);
          opacity: 0.05;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 0;
        }
      }
      img{
        width: 68px;
        height: 68px;
      }
    }
    .username{
      height: 54px;
      background: #FFFFFF;
      border: 1px solid #E0E0E0;
      border-radius: 4px;
      ::v-deep{
        .el-form-item__content{
          display: flex;
        }
        .el-input--medium{
          flex:1
        }
      }
      .username-img{
        width: 78px;
        height: 52px;
        background: #F6F8FA;
        border-radius: 4px 0px 0px 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 24px;
          height: 24px;
        }
      }
      .username-img2{
        width: 78px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          cursor: pointer;
        }
        & img:nth-child(1){
          width: 26px;
          height: 11px;
        }
        & img:nth-child(2){
          width: 26px;
          height: 18px;
        }
      }
    }

  }

}
.loginText{
  height: 27px;
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputText{
  font-size: 8px;
  color: #9ea5ae;
  font-weight: 800;
  margin-bottom: 5px;
}

.mybutton{
  height: 42px;
  border-radius: 4px;
  width: 100%;
}

.center{
  display: flex;
  justify-content: center;
  margin-top: 13px;
  position: relative;
}

  footer{
    height: 140px;
    color: #FFFFFF;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    font-family: MicrosoftYaHei-Bold;
    min-width:150px;
    background: var(--theme);
  }

.saveme{
  display: flex;
  justify-content: flex-end;
}
.erWeiMa{
  width: 250px;
  height: 250px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  img{
    width: 210px;
    height: 210px;
  }
}
</style>
