<template>
  <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on" label-position="left">
    <div class="wxsm-out">

      <div class="wxsm"><div class="bg" />微信扫码登录</div>
      <img class="click" :src="require(`@/assets/login/${$store.getters.moduleName}/3.png`)" @click="parent.type=1">
    </div>
    <div class="loginText">用户登录</div>
    <div style="padding:0 80px">
      <el-form-item prop="account" class="account">
        <div class="account-img">
          <img :src="require(`@/assets/login/${$store.getters.moduleName}/6.png`)">
        </div>
        <el-input
          ref="account"
          v-model="loginForm.account"
          placeholder="请输入用户名"
          name="account"
          type="text"
          tabindex="1"
          autocomplete="on"
        />
      </el-form-item>
      <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
        <el-form-item prop="password" class="account">
          <div class="account-img">
            <img :src="require(`@/assets/login/${$store.getters.moduleName}/4.png`)">
          </div>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="请输入密码"
            name="password"
            tabindex="2"
            autocomplete="on"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter.native="handleLogin"
          />
          <div class="account-img2">
            <img v-show="!passwordType" :src="require(`@/assets/login/${$store.getters.moduleName}/5.png`)" @click="passwordType='password'">
            <img v-show="!!passwordType" :src="require(`@/assets/login/${$store.getters.moduleName}/7.png`)" @click="passwordType=''">
          </div>
        </el-form-item>
      </el-tooltip>

      <div class="backPassword" @click="parent.type=3">找回密码?</div>
      <div class="center">
        <el-button :loading="loading" type="primary" class="mybutton" @click.native.prevent="handleLogin">登录</el-button>
      </div>
      <div class="register click" @click="parent.type=2">没有账号,去注册</div>
      <div class="saveme">
        <el-checkbox v-model="checked">记住账号密码</el-checkbox>
      </div>
    </div>
  </el-form>
</template>

<script>
export default {
  name: 'PartysiteLogin',

  data() {
    return {
      loginForm: {
        account: '',
        password: ''
      },
      loginRules: {
        account: [{ required: true, trigger: 'blur', message: '请输入用户名' }, { min: 6, message: '长度6位以上', trigger: 'blur' }],
        password: [{ required: true, trigger: 'blur', message: '请输入密码' }, { min: 6, message: '长度6位以上', trigger: 'blur' }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      checked: true
    }
  },
  computed: {
    parent() {
      return this.$parent
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  created() {
    let usersave = window.localStorage.getItem('usersave')
    if (usersave) {
      this.checked = true
      usersave = JSON.parse(usersave)
      this.loginForm.account = usersave.account
      this.loginForm.password = usersave.password
    } else {
      this.checked = false
    }
  },
  mounted() {
    if (this.loginForm.account === '') {
      this.$refs.account.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    }
  },

  methods: {
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('login/AccountLogin', this.loginForm)
            .then((res) => {
              if (this.checked) {
                window.localStorage.setItem('usersave', JSON.stringify({ account: this.loginForm.account, password: this.loginForm.password }))
              } else {
                window.localStorage.removeItem('usersave')
              }
              if (res.code === 0) {
                this.$store.commit('app/LOGIN', res.data)
              }
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */
.login-container {
  width:100%;
  height:100%;
  display:flex;
  flex-direction:column;}
</style>

<style lang="scss" scoped>
  $dark_gray:#889aa4;
$light_gray:#eee;
::v-deep{
  .el-input {
    display: flex;
    align-items: center;

    height: 47px;}
    input {
    background: transparent;
    border: 0px;
    -webkit-appearance: none;
    border-radius: 0px;
    padding: 12px 5px 12px 15px;
    height: 47px;

    &:-webkit-autofill {
      box-shadow: 0 0 0px 1000px #fff inset !important;
      -webkit-text-fill-color: unset !important;
      border-radius: 10px;
    }
  }
}
.login{
  background-size:100% 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.login-container {
  min-height: 100%;
  width: 100%;
  /* background-color: $bg; */

  overflow: hidden;

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}

.img{
  width:210px;
  height:70px;
}
.text{
  font-size:10px;
  color:#ffc693;
}
.topnav{
  display:flex;
  align-items:center;
  justify-content:space-around;
  background:rgba(255,255,255,.6);
  height:90px;
  .text{
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 136px;
    justify-content: space-between;
    div{
      font-size: 18px;
    }
  }
}
  .login-form {
    position: relative;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(239, 140, 0, 0.26);
    border-radius: 20px;
    width: 600px;
    height: 452px;
    .backPassword{
      cursor: pointer;
      width: 72px;
      height: 16px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-left: auto;
      margin-bottom: 30px;
    }
    .register{
      height: 15px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: var(--theme);
      text-align: center;
      margin-top: 20px;
    }
    .wxsm-out{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .wxsm{
        width: 146px;
        height: 36px;
        border: 1px solid var(--theme);
        border-radius: 4px;
        color: var(--theme);
        text-align: center;
        line-height: 36px;
        position: relative;
        .bg{
          background: var(--theme);
          opacity: 0.05;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 0;
        }
      }
      img{
        width: 68px;
        height: 68px;
      }
    }
    .account{
      height: 54px;
      background: #FFFFFF;
      border: 1px solid #E0E0E0;
      border-radius: 4px;
      ::v-deep{
        .el-form-item__content{
          display: flex;
          align-items: center;
        }
        .el-input--medium{
          flex:1
        }
      }
      .account-img{
        width: 78px;
        height: 52px;
        background: #F6F8FA;
        border-radius: 4px 0px 0px 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 24px;
          height: 24px;
        }
      }
      .account-img2{
        width: 78px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          cursor: pointer;
        }
        & img:nth-child(1){
          width: 26px;
          height: 11px;
        }
        & img:nth-child(2){
          width: 26px;
          height: 18px;
        }
      }
    }

  }

.loginText{
  height: 27px;
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputText{
  font-size: 8px;
  color: #9ea5ae;
  font-weight: 800;
  margin-bottom: 5px;
}

.mybutton{
  height: 42px;
  border-radius: 4px;
  width: 100%;
}

.center{
  display: flex;
  justify-content: center;
  margin-top: 13px;
  position: relative;
}

  footer{
    height: 140px;
    color: #FFFFFF;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    font-family: MicrosoftYaHei-Bold;
    min-width:150px;
    background: var(--theme);
  }

.saveme{
  display: flex;
  justify-content: flex-end;
}
.erWeiMa{
  width: 250px;
  height: 250px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  img{
    width: 210px;
    height: 210px;
  }
}
</style>
