<template>
  <div>
    <el-form ref="ruleForm" :rules="rules" label-position="right" label-width="100px" :model="formLabelAlign">
      <el-form-item label="账号名" prop="account">
        <el-input v-model="formLabelAlign.account" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="账号密码" prop="password">
        <el-input v-model="formLabelAlign.password" type="password" placeholder="6位以上的密码" />
      </el-form-item>
      <el-form-item label="确认密码" prop="agamePassword">
        <el-input v-model="formLabelAlign.agamePassword" type="password" placeholder="重复输入密码" />
      </el-form-item>
      <el-form-item label="邮箱地址" prop="email">
        <div class="aliCenter">
          <el-input v-model="formLabelAlign.email" placeholder="请输入邮箱" />
          <el-button v-loading="loadingCode" type="primary" :disabled="code!=='获取验证码'" :class="{code2:code==='获取验证码'}" class="code" @click="sendCode">{{ code }}</el-button>
        </div>
      </el-form-item>
      <el-form-item label="验证码" prop="emailCode">
        <el-input v-model="formLabelAlign.emailCode" placeholder="请输入验证码" />
      </el-form-item>
      <el-form-item label="" style="margin-top:-10px" prop="agree">
        <el-checkbox v-model="formLabelAlign.agree">同意<span @click.prevent="xy">《用户注册协议》</span></el-checkbox>
      </el-form-item>
      <el-form-item label="" style="margin-top:0px">
        <el-button :loading="loading" type="primary" class="mybutton" @click="goLogin">登录</el-button>
        <div class="register click" @click="$parent.type=0">已有账号？返回登录</div>
      </el-form-item>
    </el-form>
    <el-dialog
      title="用户注册协议"
      :visible.sync="dialogVisible"
    >
      <xy />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import xy from '@/views/xy'
export default {
  components: { xy },

  data() {
    var checkAgree = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请同意注册协议'))
      } else {
        callback()
      }
    }
    var checkPasswordAgain = (rule, value, callback) => {
      if (!value || value.length < 6) {
        return callback(new Error('密码至少6位'))
      } else if (value !== this.formLabelAlign.password) {
        return callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    }
    return {
      formLabelAlign: {
        account: '',
        password: '',
        agamePassword: '',
        email: '',
        emailCode: '',
        agree: false

      },
      dialogVisible: false,
      loading: false,
      checked: false,
      code: '获取验证码',
      rules: {
        account: [
          { required: true, message: '请输入账号名,6位以上', trigger: 'blur' },
          { min: 6, message: '长度6位以上', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码,6位以上', trigger: 'blur' },
          { min: 6, message: '长度6位以上', trigger: 'blur' }
        ],
        agamePassword: [
          { required: true, trigger: 'blur', validator: checkPasswordAgain }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' }
        ],
        emailCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        agree: [
          { required: true, trigger: 'blur', validator: checkAgree }
        ]
      },
      loadingCode: false
    }
  },

  mounted() {

  },

  methods: {
    sendCode() {
      const reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      if (!this.formLabelAlign.email) {
        this.$message.error('请输入邮箱!')
        return
      } else if (!reg.test(this.formLabelAlign.email)) {
        this.$message.error('邮箱格式不正确')
        return
      }
      if (this.code === '获取验证码') {
        this.loadingCode = true
        this.$store.dispatch('login/SendEmail', { email: this.formLabelAlign.email, sendType: 1 }).then(res => {
          if (res.code === 0) {
            this.$message.success('验证码已发送')
            this.loadingCode = false
            this.code = 60
            this.sendCode()
          } else {
            this.loadingCode = false
          }
        })
      } else if (this.code > 0) {
        setTimeout(() => {
          this.code -= 1
          this.sendCode()
        }, 1000)
      } else {
        this.code = '获取验证码'
      }
    },
    goLogin() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('login/AccountAddAccount', this.formLabelAlign).then(res => {
            if (res.code === 0) {
              const { account, password } = this.formLabelAlign
              this.$store.dispatch('login/AccountLogin', { account, password }).then(res => {
                if (res.code === 0) {
                  this.$store.commit('app/LOGIN', res.data)
                  this.loading = false
                }
              })
            } else {
              this.loading = false
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    xy() {
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep{
    .el-form-item__label{
      font-size:16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }
  }
  .mybutton{
    height: 42px;
    border-radius: 4px;
    width: 100%;
  }
  .register{
    height: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: var(--theme);
    text-align: center;
    margin-top: 10px;
  }
  .code{
    width: 110px;
    height: 40px;
    font-size: 12px;
    margin-left: 20px;

  }
  .code2{
    background: #fff;
    color: var(--theme);
  }
</style>
