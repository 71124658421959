<template>
  <div>
    <el-form ref="forgetPassword" :rules="forgetRules" label-position="right" label-width="100px" :model="formLabelAlign">
      <el-form-item label="请填写账号" prop="account">
        <el-input v-model="formLabelAlign.account" />
      </el-form-item>
      <el-form-item label="请填写邮箱" prop="email" style="margin-top:20px">
        <el-input v-model="formLabelAlign.email" />
      </el-form-item>

      <el-form-item label="" style="margin-top:40px">
        <el-button :loading="loading" type="primary" class="mybutton" @click="sendCode">发送</el-button>
        <div class="register click" @click="$parent.type=0">返回登录</div>
      </el-form-item>
    </el-form>

    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="580px"
    >
      <div class="dialog">
        <img :src="require(`@/assets/login/${$store.getters.moduleName}/11.png`)">
        <div class="text1">发送成功</div>
        <div class="text2">密码已发送到您绑定的邮箱，</div>
        <div class="text2">请登录您的邮箱：<span style="font-weight:400">【{{ formLabelAlign.email }}】</span>查看</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button style="width:140px;" class="clickIKnow" @click="dialogVisible=false">知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'PartysiteRegister',

  data() {
    return {
      formLabelAlign: {
        account: '',
        email: ''
      },
      loading: false,
      dialogVisible: false,
      forgetRules: {
        account: [{ required: true, trigger: 'blur', message: '请输入用户名' }, { min: 3, message: '长度3位以上', trigger: 'blur' }],
        email: [{ required: true, trigger: 'blur', message: '请输入邮箱' }]
      }
    }
  },
  watch: {
    dialogVisible(val) {
      if (!val) this.$parent.type = 0
    }
  },

  mounted() {

  },

  methods: {
    sendCode() {
      this.$refs.forgetPassword.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('login/AccountForgetPwd', this.formLabelAlign)
            .then((res) => {
              if (res.code === 0) {
                this.dialogVisible = true
              }
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep{
    .el-form-item__label{
      font-size:15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }
    .el-dialog__footer{
      text-align: center;
    }

  }
  .mybutton{
    height: 42px;
    border-radius: 4px;
    width: 100%;
  }
  .register{
    height: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: var(--theme);
    text-align: center;
    margin-top: 20px;
  }
  .code{
    width: 110px;
    height: 40px;
    font-size: 12px;
    margin-left: 20px;

  }
  .code2{
    background: #fff;
    color: var(--theme);
  }

  .dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
      width: 110px;
      height: 110px;
      margin-top: -25px;
      margin-bottom: 10px;
    }
    .text1{
      height: 26px;
      font-size: 25px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      margin-bottom: 45px;
    }
    .text2{
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-bottom: 10px;
    }
  }
  .clickIKnow{
    position: relative;
    top: -20px;
  }
</style>
